import {DataQueryPage, DataQueryValue} from "types"
import firebase from "firebase/app"

// This made for correct TS resolvings:
// @see https://stackoverflow.com/questions/60857851/dynamic-where-conditions-with-firestore-and-typescript
function queryCollection(path: string): firebase.firestore.Query<firebase.firestore.DocumentData> {
  return firebase.firestore().collection(path)
}

export const fetchCollection = async (path: string, queryValue: DataQueryValue & DataQueryPage):Promise<Array<firebase.firestore.DocumentSnapshot>> => {
  let query = queryCollection(path)

  if (queryValue.fieldId && queryValue.condition && queryValue.fieldValue !== undefined) {
    query = query.where(queryValue.fieldId, queryValue.condition, queryValue.fieldValue)
  }

  if (queryValue.fieldId) {
    if (queryValue.condition === '==') {
      query = query.orderBy(firebase.firestore.FieldPath.documentId())
    } else {
      query = query.orderBy(queryValue.fieldId, queryValue.order)
    }

    if (queryValue.startAfter) {
      query = query.startAfter(queryValue.startAfter)
      if (queryValue.limit) {
        query = query.limit(queryValue.limit)
      }
    } else if (queryValue.endBefore) {
      query = query.endBefore(queryValue.endBefore)
      if (queryValue.limit) {
        query = query.limitToLast(queryValue.limit)
      }
    } else {
      if (queryValue.limit) {
        query = query.limit(queryValue.limit)
      }
    }
  } else {
    if (queryValue.limit) {
      query = query.limit(queryValue.limit)
    }
  }

  const snapshot = await query.get()

  return snapshot.docs;

  // if (!snapshot.empty) {
  //   return snapshot.docs.map(doc => ({
  //     id: doc.id,
  //     ...doc.data()
  //   }))
  // } else return []
}

export const fetchDocumentById = async (collection: string, docId: string):Promise<firebase.firestore.DocumentSnapshot> => {
  const db = firebase.firestore()

  return await db.collection(collection).doc(docId).get()
}
