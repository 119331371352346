import React from 'react'
import {DataField} from "types"
import {IoCheckmarkCircleOutline, IoEllipseOutline} from "react-icons/all"
import moment from "moment"
import firebase from "firebase/app"
import {WithTooltip} from "components"

// import styles from './Td.module.scss'

type Props = React.TdHTMLAttributes<HTMLTableDataCellElement> & {
  field: DataField,
  doc: firebase.firestore.DocumentSnapshot;
  options?: {[key:string]: any};
  isDetails?: boolean;
};

export const Td: React.FC<Props> = ({ isDetails = false, field, doc, options, ...rest }) => {
  const value = field.id === 'id' ? doc.id : doc.get(field.id)

  const renderCell = () => {
    switch (field.type) {
      case 'number':
      case 'string':
        return value
      case 'boolean': return value ?
        <span className="fs-5" style={{color: 'var(--bs-success)'}}><IoCheckmarkCircleOutline/></span> :
        <span className="fs-5" style={{color: 'var(--bs-gray-500)'}}><IoEllipseOutline/></span>
      case 'date': {
        if (!value) return null;

        const date = moment((value as firebase.firestore.Timestamp).toDate())
        return (
          <WithTooltip tooltip={date.format('YYYY-MM-DD HH:mm:ss')}>
            <p className="m-0">{options?.dateFormat ? date.format(options.dateFormat) : date.fromNow(true)}</p>
          </WithTooltip>
        )
      }
      case 'array':
        return value?.length ?? 0
      case 'map':
        return Object.keys(value ?? {}).length ?? 0
    }
  }

  const renderMethod = isDetails ? field.renderDetails : field.renderList
  const renderStyle = isDetails ? field.detailsStyle : field.listStyle

  return <td {...rest} style={renderStyle}>{typeof renderMethod === 'function' ? renderMethod(field, doc) : renderCell()}</td>
}
