import {useEffect, useState} from "react"
import firebase from "firebase/app"

export function useAuthStateChanged() {
  const [ user, setUser ] = useState<firebase.User | null>(null)
  const [ isReady, setReady ] = useState(false)

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(user) {
      setUser(user)
      setReady(true)
    });
  }, [])

  return {
    user,
    setUser,
    isReady
  }
}
