import React from 'react'
import {DataField} from "types"
import {DataPage} from "containers/DataPage"
import {DataDetails} from "containers/DataDetails"
import {renderInstanceIdLink, renderIsPlayingList, renderSessionLink, renderSessionMode} from "components/helpers"
import {COLLECTIONS, ROUTES} from "config"
import {DataDocTab} from "components"


const fields:DataField[] = [
  // { id: 'id', name: 'ID', type: 'string' as const, isCustom: true, renderList: renderIdLink, listStyle: { width: '10%' }},
  {
    id: 'name',
    name: 'Name',
    type: 'string' as const,
    renderList: renderSessionLink,
    listStyle: {width: '15%', maxWidth: 0, minWidth: 150, overflow: "hidden", textOverflow: 'ellipsis', whiteSpace: 'nowrap'}
  },
  {
    id: 'instanceId',
    name: 'Instance',
    type: 'string' as const,
    renderList: renderInstanceIdLink,
    listStyle: {width: '8%'}
  },
  {id: 'settings.mode', name: 'Mode', type: 'string' as const, renderList: renderSessionMode, listStyle: {width: '6%'}},
  {id: 'appVersion', name: 'Version', type: 'string' as const, renderList: true, listStyle: {width: '5%'}},
  {id: 'gameState', name: 'State', type: 'string' as const, renderList: renderIsPlayingList, listStyle: {width: '5%'}},
  {id: 'totalIssues', name: 'Issues', type: 'number' as const, renderList: true, listStyle: {width: '5%'}},
  {id: 'participants', name: 'Users', type: 'array' as const, renderList: true, listStyle: {width: '5%'}},
  {
    id: 'settings.enableChat',
    name: 'Chat',
    type: 'boolean' as const,
    renderList: true,
    listStyle: {width: '5%'}
  },
  {id: 'savedAt', name: 'Saved', type: 'date' as const, renderList: true, listStyle: {width: '5%'}},
  {id: 'createdAt', name: 'Created', type: 'date' as const, renderList: true, listStyle: {width: '5%'}},
  {id: 'updatedAt', name: 'Updated', type: 'date' as const, renderList: true, listStyle: {width: '5%'}},
]

export const Sessions: React.FC = () => {
  return (
      <>
        <DataPage title="Sessions" collection={COLLECTIONS.SESSIONS} fields={fields} sortBy="updatedAt" sortOrder="desc"/>
        <DataDetails
          rootPath={ROUTES.SESSIONS}
          collection={COLLECTIONS.SESSIONS}
          renderTitle={doc => doc.get('name')}
          fields={fields}
          tabs={[
            {
              name: 'Instance',
              render: doc => <DataDocTab collection={COLLECTIONS.INSTANCES} id={doc.get('instanceId')}/>,
              path: '/instance'
            }
          ]}
        />
      </>
  )
}
