import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {ButtonToolbar, Table} from "react-bootstrap"
import {defaultQueryValue, DataQuery, DataTable, THead, JumpTo} from "components"
import {DataField, DataQueryValue, DataSortOrder} from "types"
import { TFooter } from 'components'
import {useFetchCollection, useFooterKeyDownHandle} from "hooks"

type Props = {
  title: string,
  fields: DataField[],
  collection: string;
  sortBy?: string;
  sortOrder?: DataSortOrder;
};

export const DataPage: React.FC<Props> = ({
  title,
  fields,
  collection,
  sortBy,
  sortOrder = 'asc'
}) => {

  const [ queryValue, setQueryValue ] = useState<DataQueryValue>({ ...defaultQueryValue, fieldId: sortBy, order: sortOrder })
  const { docs, isLoading, error } = useFetchCollection(collection, queryValue)
  const [ isScrolled, setScrolled ] = useState<boolean>(false)
  const [ isScrolledToEnd, setScrolledToEnd ] = useState<boolean>(true)
  const rowRefs = useRef<Array<HTMLTableHeaderCellElement | null>>([])
  const containerRef = useRef<HTMLDivElement | null>(null)
  const [ rowWidths, setRowWidths ] = useState<number[]>([])
  const { footerRef, handleKeyDown } = useFooterKeyDownHandle()


  const onScroll = useCallback((event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop } = event.currentTarget;
    if (scrollTop > 0){
      setScrolled(true);
    } else {
      setScrolled(false);
    }
    if (containerRef.current?.scrollHeight! <= containerRef.current?.clientHeight! || scrollTop >= containerRef.current?.scrollHeight! - containerRef.current?.clientHeight!) {
      setScrolledToEnd(true)
    } else {
      setScrolledToEnd(false);
    }
  }, []);

  const onSort = useCallback((fieldId, order) => setQueryValue(val => ({
    ...val,
    fieldId,
    order,
    page: 0,
    ...fieldId !== val.fieldId && {
      condition: undefined,
      fieldValue: undefined,
    }
  })), [])

  const handleResize = useCallback(() => {
    containerRef.current!.scrollTo(0,0)
    containerRef.current!.dispatchEvent(new CustomEvent('scroll'))

    if (rowRefs.current) setRowWidths(rowRefs.current.map(th => th?.offsetWidth ?? 0))
  }, [])

  useEffect(() => {
    handleResize()

    if (rowRefs.current) setRowWidths(rowRefs.current.map(th => th?.offsetWidth ?? 0))

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    rowRefs.current = rowRefs.current.slice(0, docs.length);
  }, [docs])

  useLayoutEffect(() => {
    handleResize()
  }, [docs, handleResize])

  return (
    <div className="p-0 h-100 d-flex flex-column" onKeyDown={handleKeyDown} tabIndex={0}>
      <div
        style={{ backgroundColor: 'var(--bs-gray-100)',
          ...isScrolled && { boxShadow: '0 3px 3px var(--bs-gray-300)'}}}
        className="d-flex flex-column pt-3 px-4">

      <div className="d-flex align-items-center justify-content-between pb-3">
          <h1 className="h3 mb-0">{title}</h1>

          <ButtonToolbar className="d-flex align-items-center">
            <DataQuery
              fields={fields}
              value={queryValue}
              onChange={val => setQueryValue(val)}
            />
            <JumpTo/>
          </ButtonToolbar>
        </div>
        {isScrolled && <Table bordered striped hover className="mb-0">
          <THead {...{fields, onSort, sortId: queryValue.fieldId, sortOrder: queryValue.order, rowWidths}} style={{ borderBottomColor: 'transparent'}}/>
        </Table>}
      </div>
      <div
        className="px-4 flex-fill"
        onScroll={onScroll}
        ref={containerRef}
        style={{ overflowX: 'hidden', overflowY: 'scroll'}}
      >
        <DataTable
          rowRefs={rowRefs}
          fields={fields}
          onSort={onSort}
          query={queryValue}
          docs={docs}
          isLoading={isLoading}
          isScrolled={isScrolled}
          error={error}
        />
      </div>
      <TFooter
        ref={footerRef}
        isLoading={isLoading}
        error={error}
        length={docs.length}
        filter={queryValue}
        onChange={newVal => setQueryValue(oldVal => ({...oldVal, ...newVal}))}
        isScrolledToEnd={isScrolledToEnd}
      />
    </div>
  )
}
