import {useCallback, useEffect, useState} from "react"
import firebase from "firebase/app"
import {DataQueryPage, DataQueryValue} from "types"
import {fetchCollection} from "services/db"
import {isEqual} from "lodash"
import {usePrevious} from "services/utils"

export const useFetchCollection = (collection: string, queryValue: DataQueryValue) => {

  const [ isLoading, setLoading ] = useState(false)
  const [ error, setError ] = useState<string | null>(null)

  const [ docs, setDocs ] = useState<firebase.firestore.DocumentSnapshot[]>([])
  const prevQueryValue = usePrevious(queryValue)

  const fetch = useCallback(async () => {
    let filterPage:DataQueryPage = {}
    if (queryValue.page !== prevQueryValue?.page) {
      if (queryValue.page) {
        if (queryValue.page > (prevQueryValue?.page ?? 0)) {
          filterPage.startAfter = docs[docs.length - 1]
        } else {
          filterPage.endBefore = docs[0]
        }
      }
    }
    setError(null)
    setLoading(true)
    try {
      const snapshot = await fetchCollection(collection, {...queryValue, ...filterPage})
      setDocs(snapshot)
    } catch (e) {
      setError((e as Error).message)
    } finally {
      setLoading(false)
    }
  }, [collection, docs, prevQueryValue?.page, queryValue])

  useEffect(() => {
    if (!isEqual(queryValue, prevQueryValue)) {
      fetch()
    }
  }, [queryValue, prevQueryValue, fetch])

  return {
    fetch,
    docs,
    isLoading,
    error
  }
}
