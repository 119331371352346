import React, {useCallback, useEffect, useState} from 'react'
import {Button, ButtonGroup, Form} from "react-bootstrap"
import {ControllerRenderProps} from "react-hook-form"
import moment from "moment"
import {usePrevious} from "services/utils"

type Props = {
  field: ControllerRenderProps;
};

export const CustomConditionValue: React.FC<Props> = ({ field }) => {
  const [ type, setType ] = useState<'string' | 'number' | 'boolean' | 'date'>('string')
  const prevType = usePrevious(type)
  const onChangeValue = useCallback((value: any) => {
    switch (type) {
      case 'number': return parseFloat(value)
      case 'date': return new Date(value)
      default: return value
    }
  }, [type])

  useEffect(() => {
    if (type !== prevType && field.value !== undefined) {
      field.onChange(undefined)
    }
  }, [type,prevType, field])

  return (
    <>
      <ButtonGroup className="mt-3 w-100" size="sm">
        {['string' as const, 'number' as const, 'boolean' as const, 'date' as const].map((option, index) => (
          <Button key={index} className="font-monospace" variant="outline-secondary" active={type === option} onClick={() => setType(option)}>{option}</Button>
        ))}
      </ButtonGroup>
      {type === 'boolean' ? (
        <ButtonGroup className="mt-3 w-100">
          {[true, false].map((option, index) => (
            <Button key={index} className="font-monospace" variant="outline-secondary" active={field.value === option} onClick={() => field.onChange(option)}>{String(option)}</Button>
          ))}
        </ButtonGroup>
      ) : (
        <Form.Control
          {...field}
          value={type === 'date' ? moment(field.value).format('YYYY-MM-DD') : (field.value ?? '')}
          className="mt-3"
          onChange={e => field.onChange(onChangeValue(e.currentTarget.value))}
          type={type}
        />
      )}
    </>
  )
}
