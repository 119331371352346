import React, {useMemo} from 'react'
import {Table} from "react-bootstrap"
import firebase from "firebase/app"
import {Loading} from "components/Loading"
import moment from "moment"

type Props = {
  doc?: firebase.firestore.DocumentSnapshot,
  isLoading?: boolean;
};

export const DataDoc: React.FC<Props> = ({ doc, isLoading }) => {

  const docKeys = useMemo(() => doc?.exists
    ? Object.keys(doc.data()!)
      .sort()
    //.filter(k => fields.map(f => f.id).includes(k))
    : [], [doc])

  if (isLoading || !doc) return <Loading/>

  const renderValue = (value: any) => {
    switch (true) {
      case value instanceof firebase.firestore.Timestamp: return (
        <span className="small font-monospace">
          {moment((value as firebase.firestore.Timestamp).toDate()).toLocaleString()}
        </span>
      )
      case typeof value === 'object': return (
        <pre className="m-0">
          {JSON.stringify(value, null, 2)}
        </pre>
      )
      default: return (
        <span className="small font-monospace">
          {value.toString()}
        </span>
      )
    }
  }

  return (
    <Table striped borderless className="w-100" style={{ wordBreak: 'break-all'}}>
      <thead>
      <tr>
        <th style={{width: '25%'}}>Key</th>
        <th>Value</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td>ID</td>
        <td><span className="small font-monospace">{doc?.id}</span></td>
      </tr>
      {docKeys.map(key => {
        const value = doc!.get(key)

        return (
          <tr key={key}>
            <td><span className="small font-monospace">{key}</span></td>
            <td>{renderValue(value)}
            </td>
          </tr>
        )
      })}
      </tbody>
    </Table>
  )
}
