import React from 'react'
import {DataPage} from "containers/DataPage"
import {DataDetails} from "containers/DataDetails"
import {DataField} from "types"
import {renderUserLink} from "components/helpers"
import {COLLECTIONS, ROUTES} from "config"

const fields: DataField[] = [
  {
    id: 'id',
    name: 'ID',
    type: 'string' as const,
    isCustom: true,
    renderList: renderUserLink,
    renderDetails: true,
    listStyle: {width: '10%'}
  },
  {
    id: 'lastViewedGetStarted',
    name: 'Get Started',
    type: 'date' as const,
    renderList: true,
    renderDetails: true,
    listStyle: {width: '5%'}
  },
  {
    id: 'lastViewedWhatsNew',
    name: 'Whats New',
    type: 'date' as const,
    renderList: true,
    renderDetails: true,
    listStyle: {width: '5%'}
  },
  {
    id: 'updatedAt',
    name: 'Updated',
    type: 'date' as const,
    renderList: true,
    renderDetails: true,
    listStyle: {width: '5%'}
  },
  {
    id: 'isAdmin',
    name: 'Admin',
    type: 'boolean' as const,
    renderList: true,
    renderDetails: true,
    listStyle: {width: '5%'}
  },
]

export const Users: React.FC = () => {

  return (
    <>
      <DataPage title="Users" collection={COLLECTIONS.USERS} fields={fields} sortBy="updatedAt" sortOrder="desc"/>
      <DataDetails
        rootPath={ROUTES.USERS}
        collection={COLLECTIONS.USERS}
        fields={fields}
      />
    </>
  )
}
