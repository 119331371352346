import React, {useContext} from 'react'
import { ReactComponent as Logo } from 'images/app-logo.svg'
import {UserContext} from "services/context"
import {Badge, Dropdown, Nav, NavLink} from "react-bootstrap"
import {ROUTES} from "config"
import {
  IoBarChart,
  IoFileTrayFull,
  IoFlag,
  IoFolderOpen,
  IoLogOutOutline,
  IoPeople,
  IoPersonCircleOutline,
  IoSettingsOutline,
  IoTimer
} from "react-icons/io5";
import firebase from "firebase/app"
import {LinkContainer} from 'react-router-bootstrap'
import {IconType} from "react-icons"
import {Link} from "react-router-dom"

type LayoutMenu = Array<{ to: string, title: string, icon: IconType, badge?: string | number}>

const menu:LayoutMenu = [
  { title: 'Dashboard', to: ROUTES.DASHBOARD, icon: IoBarChart },
  { title: 'Instances', to: ROUTES.INSTANCES, icon: IoFileTrayFull },
  { title: 'Sessions', to: ROUTES.SESSIONS, icon: IoTimer },
  { title: 'Reports', to: ROUTES.REPORTS, icon: IoFolderOpen },
  { title: 'Users', to: ROUTES.USERS, icon: IoPeople },
  { title: 'Feedback', to: ROUTES.FEEDBACK, icon: IoFlag }
]

type Props = {};

export const Layout: React.FC<Props> = ({children}) => {

  const { user } = useContext(UserContext)

  return (
  <div className="d-flex h-100 w-100 flex-shrink-0">
    <div className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark" style={{width: '280px'}}>
      <Link to={ROUTES.HOME} className="text-decoration-none">
        <div className="d-flex align-items-center text-white text-decoration-none ps-2">
          <Logo width={32} className="me-2" fill="#fff"/>
          <strong className="fs-3">CRM</strong>
        </div>
      </Link>
      <hr/>
      <Nav variant="pills" className="flex-column mb-auto">
        {menu.map(item => (
          <Nav.Item key={item.to} className="h5">
            <LinkContainer to={item.to}>
              <Nav.Link className="text-white d-flex align-items-center" active={false}>
                <item.icon className="h4 me-3 mb-0"/>
                <span className="me-auto">{item.title}</span>
                {item.badge && (
                  <small><Badge pill bg="danger">
                    {item.badge}
                  </Badge>
                  </small>
                )}
              </Nav.Link>
            </LinkContainer>
          </Nav.Item>
        ))}
      </Nav>
      <hr/>

      <Dropdown>
        <Dropdown.Toggle size="sm" as={NavLink} className="p-0 d-flex align-items-center text-white text-decoration-none">
          <IoPersonCircleOutline className="h3 me-2 mb-0"/>
          <strong className="d-inline-block text-truncate text-small">{user?.email}</strong>
        </Dropdown.Toggle>

        <Dropdown.Menu variant="dark" align="end" className="text-small shadow">
          <Dropdown.Item className="d-flex align-items-center"><IoSettingsOutline className="fs-5 me-2"/> Settings</Dropdown.Item>
          <Dropdown.Divider/>
          <Dropdown.Item className="d-flex align-items-center" onClick={() => {firebase.auth().signOut()}}>
            <IoLogOutOutline className="fs-5 me-2"/>Sign out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
    <div className="flex-fill overflow-hidden">
      {children}
    </div>
  </div>
)}
