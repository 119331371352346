import React, {forwardRef, useCallback, useImperativeHandle} from 'react'
import {Pagination, Spinner} from "react-bootstrap"
import {BsArrow90DegUp, IoCaretBack, IoCaretForward} from "react-icons/all"
import {DataQueryValue} from "types"

type Props = {
  filter: DataQueryValue;
  onChange: (filter: Partial<DataQueryValue>) => void;
  length: number;
  isLoading?: boolean;
  error: string | null;
  isScrolledToEnd: boolean;
};

type TFooterPageHandler = {
  nextPage: () => void;
  prevPage: () => void;
};

const TFooter: React.ForwardRefRenderFunction<TFooterPageHandler, Props> = ({
   filter,
   onChange,
   length,
   isLoading,
   error,
   isScrolledToEnd
}, forwardedRef) => {
  useImperativeHandle(forwardedRef, () => ({
    nextPage() {
      handleNextPage();
    },
    prevPage() {
      handlePrevPage();
    }
  }));

  const handlePrevPage = useCallback(() => {
    if (isLoading || !filter.fieldId || filter.page === 0) return

    onChange({ page: filter.page - 1 })
  }, [filter.fieldId, filter.page, isLoading, onChange])

  const handleNextPage = useCallback(() => {
    if (isLoading || !filter.fieldId || length < filter.limit!) return

    onChange({ page: filter.page + 1 })
  }, [filter.fieldId, filter.limit, filter.page, isLoading, length, onChange])

  const result = length > 0 ?
    `${filter.page * filter.limit! + 1}-${filter.page * filter.limit! + (length)}` :
    0

  if (error) return null;

  return (
    <div
      className="px-4 py-3 d-flex align-items-center justify-content-between"
      style={!isScrolledToEnd ? { boxShadow: '0 -3px 3px #dee2e6'}: undefined}
    >
      {isLoading ?
        <Spinner animation="border" size="sm" style={{ color: 'var(--bs-blue-500)'}}/> :
        <p className="m-0 text-muted d-flex align-items-center">
          <BsArrow90DegUp className="me-1" style={{ color: 'var(--bs-gray-500)'}}/>
          <span>Showing <strong>{result}</strong> documents</span>
        </p>
      }
      <Pagination className="m-0">
        <Pagination.Item
          disabled={isLoading || !filter.fieldId || filter.page === 0}
          onClick={() => onChange({ page: filter.page - 1 })}
        >
          <IoCaretBack/>
        </Pagination.Item>
        <Pagination.Item
          disabled={isLoading || !filter.fieldId || length < filter.limit!}
          onClick={() => onChange({ page: filter.page + 1 })}
        >
          <IoCaretForward/>
        </Pagination.Item>
      </Pagination>
    </div>
  )
}

export default forwardRef(TFooter)
