import React, {RefObject} from 'react'
import {Th} from "./Th"
import {DataField, DataSortOrder} from "types"

// import styles from './SortedTh.module.scss'

type Props = React.HTMLAttributes<HTMLTableSectionElement> & {
  fields: DataField[],
  onSort?: (name: string, order: DataSortOrder) => void;
  sortId?: string;
  sortOrder?: DataSortOrder;
  rowRefs?: RefObject<Array<HTMLTableHeaderCellElement | null>>;
  rowWidths?: number[];
};

export const THead: React.FC<Props> = ({fields, onSort, sortId, sortOrder, rowRefs, rowWidths, ...rest}) => {
  return (
    <thead {...rest}>
        {rowRefs ? (<tr>
          <th ref={el => rowRefs.current![0] = el}>#</th>
          {fields.filter(f => Boolean(f.renderList)).map((field, index) => (
            field.isCustom ?
              <th ref={el => rowRefs.current![index+1] = el} key={field.id}>{field.name}</th>:
              <Th thRef={el => rowRefs.current![index+1] = el} key={field.id} order={sortId === field.id ? sortOrder! : undefined} id={field.id} onSort={onSort}>{field.head ?? field.name}</Th>
          ))}
        </tr>) : (
          <tr>
            <th style={rowWidths ? { minWidth: rowWidths[0] ?? 0} : undefined}>#</th>
            {fields.filter(f => Boolean(f.renderList)).map((field, index) => (
              field.isCustom ?
                <th style={rowWidths ? { minWidth: rowWidths![index+1]} : undefined} key={field.id}>{field.name}</th>:
                <Th style={rowWidths ? { minWidth: rowWidths![index+1]} : undefined} key={field.id} order={sortId === field.id ? sortOrder! : undefined} id={field.id} onSort={onSort}>{field.head ?? field.name}</Th>
            ))}
          </tr>
        )}
    </thead>
  )
}
