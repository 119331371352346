import React, {useMemo} from 'react'

import {Button, ButtonToolbar, Card, Col, Container, Row} from "react-bootstrap"
import {Link} from "react-router-dom"
import {COLLECTIONS, ROUTES} from "config"
import {IoExitOutline} from "react-icons/all"
import firebase from "firebase/app"
import cn from "classnames"
import {useFetchDocument} from "hooks"
import moment from "moment"
import {Loading} from "components/Loading"

type DashboardStats = Array<{
  link: string;
  title: string;
  total: number;
  plus: number;
}>

const getLengthOfReportMap = (reportValue: {[key:string]: number} | undefined) => {
  return reportValue ? Object.keys(reportValue).reduce((total,key) => total + reportValue[key], 0) : 0
}

type Props = {};

export const Dashboard: React.FC<Props> = () => {

  const { doc: now, isLoading: isLoadingNow } = useFetchDocument(COLLECTIONS.REPORTS, 'now')
  const { doc: today, isLoading: isLoadingToday } = useFetchDocument(COLLECTIONS.REPORTS, moment().format('DD-MM-YYYY'))

  const stats: DashboardStats = useMemo(() => [
    {
      link: ROUTES.INSTANCES,
      title: 'installed instances',
      total: now?.get('install'),
      plus: today && today.exists ? ((today?.get('reinstall')?.length ?? 0) + (today?.get('install')?.length ?? 0) - (today?.get('uninstall')?.length ?? 0)) : 0
    },
    {
      link: ROUTES.SESSIONS,
      title: 'active sessions',
      total: now?.get('session'),
      plus: today ? getLengthOfReportMap(today?.get('session')) - getLengthOfReportMap(today?.get('clear')) : 0
    },
    {
      link: ROUTES.USERS,
      title: 'users total',
      total: 0,
      plus: 0
    },
  ], [today, now])


  return (
    <Container className="py-3 px-4" fluid style={{overflowY: 'scroll'}}>
      <div className="d-flex align-items-center justify-content-between pb-3">
        <h1 className="h3 mb-0">Dashboard</h1>
        <ButtonToolbar>
          <Button variant="outline-danger" className="d-flex align-items-center" onClick={() => firebase.auth().signOut()}>
            <IoExitOutline className="me-2"/>
            <span>Logout</span>
          </Button>
        </ButtonToolbar>
      </div>
      {(isLoadingToday || isLoadingNow) ? <Loading inline/> : (
        <Row>
          {stats.map(stat => (
            <Col className="mb-3" key={stat.title}>
              <Card>
                <Link to={stat.link} className="text-black text-decoration-none">
                  <Card.Body className="p-5 d-flex flex-row align-items-center justify-content-between">
                    <div>
                      <div className="fw-bold" style={{fontSize: '4rem'}}>{stat.total}</div>
                      <h5 className="text-muted">{stat.title}</h5>
                    </div>
                    <div className="text-right mb-5">
                      <div
                        className={cn(
                          'fw-bold',
                          stat.plus === 0 && 'text-muted',
                          stat.plus > 0 && 'text-success',
                          stat.plus < 0 && 'text-danger'
                        )}
                        style={{fontSize: '3rem'}}
                      >
                        {stat.plus >= 0 ? '+' : ''}{stat.plus}
                      </div>
                    </div>
                  </Card.Body>
                </Link>
              </Card>
            </Col>
          ))}
        </Row>
      )}
      <hr/>
    </Container>
  )
}
