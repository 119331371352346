import React from 'react'
import {DataPage} from "containers/DataPage"
import {DataDetails} from "containers/DataDetails"
import {DataField} from "types"
import {renderDetailsLink, renderUserLink} from "components/helpers"
import {COLLECTIONS, ROUTES} from "config"
import { DataDocTab } from "components"

const fields: DataField[] = [
  {
    id: 'id',
    name: 'ID',
    type: 'string' as const,
    isCustom: true,
    renderList: renderDetailsLink,
    renderDetails: true,
    listStyle: {width: '10%'}
  },
  {
    id: 'instanceId',
    name: 'Instance',
    type: 'string' as const,
    renderList: true,
    listStyle: {width: '10%'}
  },
  {
    id: 'accountId',
    name: 'User ID',
    type: 'string' as const,
    renderList: renderUserLink,
    renderDetails: true,
    listStyle: {width: '10%'}
  },
  {
    id: 'updatedAt',
    name: 'Updated',
    type: 'date' as const,
    renderList: true,
    renderDetails: true,
    listStyle: {width: '5%'}
  }
]

export const Feedbacks: React.FC = () => {

  return (
    <>
      <DataPage title="Feedback" collection={COLLECTIONS.FEEDBACK} fields={fields} sortBy="updatedAt" sortOrder="desc"/>
      <DataDetails
        rootPath={ROUTES.FEEDBACK}
        collection={COLLECTIONS.FEEDBACK}
        fields={fields}
        tabs={[
          {
            name: 'User',
            render: doc => <DataDocTab collection={COLLECTIONS.USERS} id={doc.get('accountId')}/>,
            path: '/user'
          },
          {
            name: 'Instance',
            render: doc => <DataDocTab collection={COLLECTIONS.INSTANCES} id={doc.get('instanceId')}/>,
            path: '/instance'
          }
        ]}
      />
    </>
  )
}
