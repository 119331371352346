import React from 'react'

import {useFetchCollection} from "hooks"
import {defaultQueryValue} from "components/DataQuery"
import {useParams} from "react-router"
import {DataTable} from "./DataTable"
import {DataField} from "types"

type Props = {
  fields: DataField[],
  collection: string;
  fieldId: string;
}

export const DataTableTab: React.FC<Props> = ({ fields, collection, fieldId }) => {

  const { docId } = useParams<{docId: string}>()
  const query = { ...defaultQueryValue, fieldId, condition: '==' as const, fieldValue: docId }
  const { docs, isLoading, error } = useFetchCollection(collection, query)

  return (
    <DataTable
      fields={fields}
      query={query}
      docs={docs}
      isLoading={isLoading}
      error={error}
    />

  )
}
