import React from 'react'
import {DataPage} from "containers/DataPage"
import {DataDetails} from "containers/DataDetails"
import {DataField} from "types"
import {
  renderInstanceIdLink,
  renderIsPlayingList,
  renderJiraId,
  renderSessionLink,
  renderSessionMode
} from "components/helpers"
import {getJiraIdFromUrl} from "services/utils"
import {COLLECTIONS, ROUTES} from "config"
import {DataTableTab} from "components"

const sessionsFields:DataField[] = [
  {
    id: 'name',
    name: 'Name',
    type: 'string' as const,
    renderList: renderSessionLink,
    listStyle: {width: '10%', maxWidth: 0, overflow: "hidden", textOverflow: 'ellipsis', whiteSpace: 'nowrap'}
  },
  {id: 'settings.mode', name: 'Mode', type: 'string' as const, renderList: renderSessionMode, listStyle: {width: '5%'}},
  {id: 'appVersion', name: 'Version', type: 'string' as const, renderList: true, listStyle: {width: '5%'}},
  {id: 'gameState', name: 'State', type: 'string' as const, renderList: renderIsPlayingList, listStyle: {width: '5%'}},
  {id: 'totalIssues', name: 'Issues', type: 'number' as const, renderList: true, listStyle: {width: '5%'}},
  {id: 'participants', name: 'Users', type: 'array' as const, renderList: true, listStyle: {width: '5%'}},
  {id: 'savedAt', name: 'Saved', type: 'date' as const, renderList: true, listStyle: {width: '5%'}},
  {id: 'createdAt', name: 'Created', type: 'date' as const, renderList: true, listStyle: {width: '5%'}},
  {id: 'updatedAt', name: 'Updated', type: 'date' as const, renderList: true, listStyle: {width: '5%'}},
]

const fields: DataField[] = [
  {
    id: 'id',
    name: 'ID',
    type: 'string' as const,
    isCustom: true,
    renderList: renderInstanceIdLink,
    renderDetails: true,
    listStyle: {width: '10%'}
  },
  {
    id: 'baseUrl',
    name: 'Base url',
    head: 'Jira ID',
    type: 'string' as const,
    renderList: renderJiraId,
    renderDetails: true,
    listStyle: {width: '10%'}
  },
  {id: 'key', name: 'App', type: 'string' as const, renderList: true, renderDetails: true, listStyle: {width: '10%'}},
  {
    id: 'acVersion',
    name: 'AC Version',
    type: 'string' as const,
    renderList: true,
    renderDetails: true,
    listStyle: {width: '5%'}
  },
  {
    id: 'appVersion',
    name: 'Version',
    type: 'string' as const,
    renderList: true,
    renderDetails: true,
    listStyle: {width: '5%'}
  },
  {
    id: 'meta.users',
    name: 'Users',
    type: 'number' as const,
    renderList: true,
    renderDetails: true,
    listStyle: {width: '5%'}
  },
  {
    id: 'isInstalled',
    name: 'Installed',
    type: 'boolean' as const,
    renderList: true,
    renderDetails: true,
    listStyle: {width: '1%'}
  },
  {
    id: 'createdAt',
    name: 'Created',
    type: 'date' as const,
    renderList: true,
    renderDetails: true,
    listStyle: {width: '5%'}
  },
  {
    id: 'updatedAt',
    name: 'Updated',
    type: 'date' as const,
    renderList: true,
    renderDetails: true,
    listStyle: {width: '5%'}
  },
]

export const Instances: React.FC = () => {

  return (
    <>
      <DataPage title="Instances" collection={COLLECTIONS.INSTANCES} fields={fields} sortBy="createdAt" sortOrder="desc"/>
      <DataDetails
        rootPath={ROUTES.INSTANCES}
        collection={COLLECTIONS.INSTANCES}
        renderTitle={doc => getJiraIdFromUrl(doc.get('baseUrl'))}
        fields={fields}
        tabs={[
          {
            name: 'Sessions',
            path: '/sessions',
            render: () => <DataTableTab
              fields={sessionsFields}
              collection={COLLECTIONS.SESSIONS}
              fieldId="instanceId"
            />
          }
        ]}
      />
    </>
  )
}
