import React from 'react';

import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import {Layout, Signin, Instances, Dashboard, Sessions, Reports, Users, Feedbacks} from "containers"
import {NotFound, PrivateRoute} from 'components';
import {ROUTES} from "config"
import {useAuthStateChanged} from "hooks"
import { UserContext } from 'services/context'


function App() {
  const { user, setUser, isReady } = useAuthStateChanged()

  return (
    <UserContext.Provider value={{ user, setUser, isReady }}>
      <BrowserRouter>
        <Switch>
          <Route path={ROUTES.SIGNIN}>
            <Signin/>
          </Route>
          <PrivateRoute path={ROUTES.HOME}>
            <Layout>
              <Switch>
                <Redirect exact from={ROUTES.HOME} to={ROUTES.DASHBOARD}/>
                <Route exact path={ROUTES.DASHBOARD}>
                  <Dashboard/>
                </Route>
                <Route path={`${ROUTES.INSTANCES}/:docId?`}>
                  <Instances/>
                </Route>
                <Route path={`${ROUTES.SESSIONS}/:docId?`}>
                  <Sessions/>
                </Route>
                <Route path={`${ROUTES.REPORTS}/:docId?`}>
                  <Reports/>
                </Route>
                <Route path={`${ROUTES.USERS}/:docId?`}>
                  <Users/>
                </Route>
                <Route path={`${ROUTES.FEEDBACK}/:docId?`}>
                  <Feedbacks/>
                </Route>
                <Route>
                  <NotFound/>
                </Route>
              </Switch>
            </Layout>
          </PrivateRoute>
        </Switch>
      </BrowserRouter>
    </UserContext.Provider>
);
}

export default App;
