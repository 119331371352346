import React from 'react'
import {OptionProps, components} from "react-select"
import {OptionType} from "types"

const SelectOption = ({ children, ...props}: OptionProps<OptionType, false>) => {
  return (
      <components.Option {...props} className="">
        <span className="text-truncate">{props.data.label}</span>
        <small className="ms-auto text-muted font-monospace text-truncate">{props.data.value}</small>
      </components.Option>
  );
};

export default SelectOption
