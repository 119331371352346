import {useCallback, useEffect, useState} from "react"
import firebase from "firebase/app"
import {fetchDocumentById} from "services/db"

export function useFetchDocument(collection: string, docId: string) {
  const [ isLoading, setLoading ] = useState(false)
  const [ error, setError ] = useState<string | null>(null)
  const [ doc, setDoc ] = useState<firebase.firestore.DocumentSnapshot | undefined>(undefined)

  const fetch = useCallback(async () => {
    setError(null)
    setLoading(true)
    try {
      const fetchedDoc = await fetchDocumentById(collection, docId)
      setDoc(fetchedDoc)
    } catch (e) {
      setError((e as Error).message)
    } finally {
      setLoading(false)
    }
  }, [collection, docId])

  useEffect(() => {
    fetch()
  }, [fetch])

  return {
    doc,
    error,
    isLoading
  }
}
