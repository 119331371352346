export const DEV_MODE = process.env.NODE_ENV !== 'production'

export const ROUTES = {
  HOME: '/',
  INSTANCES: '/instances',
  DASHBOARD: '/dashboard',
  SESSIONS: '/sessions',
  REPORTS: '/reports',
  USERS: '/users',
  FEEDBACK: '/feedback',
  SIGNIN: '/signin'
}

export const COLLECTIONS = {
  INSTANCES: 'instances',
  SESSIONS: 'sessions',
  REPORTS: 'reports',
  USERS: 'users',
  FEEDBACK: 'feedback'
}

// prod configuration
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyDBiHNg0MaxFwHz9ZXnij1DuXZ9x6DTHcM",
  authDomain: "magestiapp.firebaseapp.com",
  databaseURL: "https://magestiapp.firebaseio.com",
  projectId: "magestiapp",
  storageBucket: "magestiapp.appspot.com",
  messagingSenderId: "706379069045",
  appId: "1:706379069045:web:9cb945b4c4acc222fe6a3c",
  measurementId: "G-QCN0BBGD44"
}

// dev / staging configuration
export const FIREBASE_CONFIG_DEV =
  {
    apiKey: "AIzaSyDtAVMPf-YFFzjkNlwpfNSGDPyobyYfAek",
    authDomain: "magesti-stage.firebaseapp.com",
    databaseURL: "https://magesti-stage-default-rtdb.firebaseio.com",
    projectId: "magesti-stage",
    storageBucket: "magesti-stage.appspot.com",
    messagingSenderId: "651168844325",
    appId: "1:651168844325:web:36ab047b3bc1158a9e8dcc"
  }

