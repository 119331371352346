import React, {useContext} from 'react'
import { Route, RouteProps, Redirect, useLocation } from 'react-router-dom'
import {ROUTES} from "config"
import {UserContext} from "services/context"
import {Loading} from "components"

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded
export const PrivateRoute:React.FC<RouteProps> = ({ children, ...rest }) => {

  const { user, isReady } = useContext(UserContext)
  const { pathname } = useLocation()

  if (!isReady) return (
    <Loading/>
  )

  return (
    <Route
      {...rest}
      render={() =>
        user ? (
          children
        ) : (
          <Redirect to={`${ROUTES.SIGNIN}${pathname !== '/' ? `?back=${pathname}` : ''}`}/>
        )
      }
    />
  )
}
