import {Link} from "react-router-dom"
import {ROUTES} from "config"
import {GoPrimitiveSquare, IoPauseCircleOutline, IoPlayCircleOutline, IoStopCircleOutline} from "react-icons/all"
import {getJiraIdFromUrl, stringToColor} from "services/utils"
import {WithTooltip} from "components/WithTooltip"
import React from "react"
import {DataField} from "types"
import firebase from "firebase/app"

import asyncImage from "images/mode/async.svg"
import classicImage from "images/mode/classic.svg"
import pokerImage from "images/mode/poker.svg"
import relativeImage from "images/mode/relative.svg"

import {Image} from "react-bootstrap"
import {useHistory} from "react-router"

export const renderInstanceIdLink = (field: DataField, doc: firebase.firestore.DocumentSnapshot) => {
  const value = field.id === 'id' ? doc.id : doc.get(field.id)
  const link = field.id === 'id' ? `${ROUTES.INSTANCES}/${value}` : `${ROUTES.SESSIONS}/${doc.id}/instance`

  return (
    <Link to={link} className="d-flex align-items-center flex-nowrap">
      <GoPrimitiveSquare style={{ color: stringToColor(value)}} className="me-2 f-6"/>
      {value.substr(0,7)}
    </Link>
  )
}

export const renderReportLink = (field: DataField, doc: firebase.firestore.DocumentSnapshot) => {
  const value = field.id === 'id' ? doc.id : doc.get(field.id)

  return (
    <Link to={`${ROUTES.REPORTS}/${value}`}>
      {value}
    </Link>
  )
}

export const renderUserLink = (field: DataField, doc: firebase.firestore.DocumentSnapshot) => {
  const value = field.id === 'id' ? doc.id : doc.get(field.id)

  return (
    <Link to={`${ROUTES.USERS}/${value}`}>
      {value}
    </Link>
  )
}

export const renderIsPlayingList = (field: DataField, doc: firebase.firestore.DocumentSnapshot) => {
  const value = doc.get(field.id)

  return value === 'play' ?
    <span className="fs-5" style={{color: 'var(--bs-success)'}}><IoPlayCircleOutline/></span> :
    <span className="fs-5" style={{color: value === 'pause' ? 'var(--bs-blue-300)' :'var(--bs-gray-500)'}}>{ value === 'pause' ? <IoPauseCircleOutline/> :<IoStopCircleOutline/>}</span>
}

export const renderSessionMode = (field: DataField, doc: firebase.firestore.DocumentSnapshot) => {
  const value = doc.get(field.id)

  const modeImages: {[key: string]: { image: string, title: string }} = {
    'async': { image: asyncImage, title: 'Async' },
    'classic': { image: classicImage, title: 'Classic' },
    'poker': { image: pokerImage, title: 'Poker' },
    'relative': { image: relativeImage, title: 'Relative' }
  }

  return modeImages[value] ? (
    <div style={{ display: 'flex' }}>
      <Image style={{ width: '1.5rem', height: '1.5rem', marginRight: '0.5rem' }} src={modeImages[value]?.image}/>
      {modeImages[value].title}
    </div>
  ) : null
}

export const renderSessionLink = (field: DataField, doc: firebase.firestore.DocumentSnapshot) => {
  return (<WithTooltip tooltip={doc.id}>
    <Link to={`${ROUTES.SESSIONS}/${doc.id}`} className="text-truncate" style={{ maxWidth: '100%' }}>{doc.get(field.id)}</Link>
  </WithTooltip>)
}


export const renderDetailsLink = (field: DataField, doc: firebase.firestore.DocumentSnapshot) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { location } = useHistory()

  return (<WithTooltip tooltip={doc.id}>
    <Link to={`${location.pathname}/${doc.id}`} className="text-truncate" style={{ maxWidth: '100%' }}>{doc.id}</Link>
  </WithTooltip>)
}

export const renderJiraId = (field: DataField, doc: firebase.firestore.DocumentSnapshot) => {
  const value = doc.get(field.id)

  return getJiraIdFromUrl(value)
}
