import React, {useCallback, useEffect, useMemo, useState} from 'react'

import {
  Accordion,
  ButtonGroup,
  Form,
  ButtonToolbar,
  Button, Dropdown
} from "react-bootstrap"
import {Controller, useForm} from "react-hook-form"
import {DataField, DataQueryValue, OptionType} from "types"
import {IoCodeSlashOutline, IoCodeWorkingOutline} from "react-icons/all"
import Select from "react-select"
import Creatable from "react-select/creatable"
import {selectStyles, selectComponents} from "./select-config"
import {ControllerRenderProps} from "react-hook-form"
import {CustomConditionValue} from "./CustomConditionValue"
import moment from "moment"

const conditions: OptionType[] = [
  { label: 'equal to', value: '==' },
  { label: 'not equal to', value: '!=' },
  { label: 'greater than', value: '>' },
  { label: 'greater than or equal to', value: '>=' },
  { label: 'less than', value: '<' },
  { label: 'less than or equal to', value: '<=' },
  { label: 'equal to any', value: 'in' },
  { label: 'not equal to any', value: 'not-in' },
  { label: 'array containing', value: 'array-contains' },
  { label: 'array containing any', value: 'array-contains-any' },
]

const getOptionsFromFields = (fields: DataField[]) => fields.filter(f => !f.isCustom).map(field => ({
  value: field.id,
  label: field.name
}))

export const defaultQueryValue: DataQueryValue = {
  fieldId: undefined,
  condition: undefined,
  fieldValue: undefined,
  order: 'asc',
  limit: 25,
  page: 0
}

type AccordionSection = 'fieldId' | 'condition' | 'order' | 'limit' | undefined

type Props = {
  fields: DataField[];
  value: DataQueryValue;
  onChange: (value: DataQueryValue) => void;
};

export const DataQuery: React.FC<Props> = ({ fields, value, onChange }) => {

  const [ isShow, setShow ] = useState(false)
  const [ activeKey, setActiveKey ] = useState<AccordionSection>('fieldId')

  const {
    control,
    watch,
    reset,
    handleSubmit,
    formState: { isDirty },
    setValue
  } = useForm({
    defaultValues: value ?? { ...defaultQueryValue }
  })

  const { fieldId, condition, fieldValue, order, limit } = watch()

  useEffect(() => {
    if (value) reset(value)
  }, [reset, value])

  const onReset = useCallback(() => {
    onChange({...defaultQueryValue})
    setActiveKey('fieldId')
    setShow(false)
  }, [onChange])

  const onSubmit = handleSubmit((currentValue) => {
    onChange({ ...currentValue, page: 0 })
    setShow(false)
  })

  const handleShow = useCallback(() => {
    if (!value.fieldId) {
      setActiveKey('fieldId')
    } else if (!value.condition || !value.fieldValue) {
      setActiveKey('condition')
    }
    setShow(true)
  }, [value])

  const handleAccordion = useCallback((eventKey: AccordionSection) => {
    setActiveKey(eventKey !== activeKey ? eventKey : undefined)
  }, [activeKey])

  useEffect(() => {
    if (fieldId) setActiveKey('condition')
  }, [fieldId])

  const currentField = useMemo(() => fields.find(f => f.id === fieldId),
    [fields, fieldId])

  useEffect(() => {
    setValue('fieldValue', undefined)
    // eslint-disable-next-line
  }, [currentField?.type])

  const renderValueControl = ({ onChange, ...field}: ControllerRenderProps) => {
    switch (currentField?.type) {
      case 'string':
        return (
          <Form.Control
            className="mt-3"
            {...field}
            value={field.value ?? ''}
            onChange={e => onChange(e)}
            type="text"
          />
        )
      case 'boolean':
        return (
          <ButtonGroup className="mt-3 w-100">
            {[true, false].map((option, index) => (
              <Button
                key={index}
                className="font-monospace"
                variant="outline-secondary"
                active={field.value === option}
                onClick={() => onChange(option)}
              >
                {String(option)}
              </Button>
            ))}
          </ButtonGroup>
        );
      case 'number':
        return (
          <Form.Control
            className="mt-3"
            {...field}
            onChange={e => {
              let numberVal = parseFloat(e.currentTarget.value)
              onChange(numberVal)
            }}
            type="number"
          />
        )
      case 'date':
        return (
          <Form.Control
            className="mt-3"
            {...field}
            value={field.value ? moment(field.value).format('YYYY-MM-DD') : ''}
            onChange={e => {
              let dateVal = new Date(e.currentTarget.value)
              onChange(dateVal)
            }}
            type="date"
          />
        )

      default:
        return <CustomConditionValue {...{field: { ...field, onChange }}} />
    }
  }

  const fieldOptions = useMemo(() => getOptionsFromFields(fields), [fields])

  return (
    <Dropdown show={isShow} onToggle={(show) => setShow(show)}>
      <Dropdown.Toggle variant="outline-secondary" className="me-3" onClick={handleShow}>
        <span className="d-inline-flex align-items-baseline">
          <span className="fs-5 my-n1 mt-n3 me-2">
            {value?.fieldId ? <IoCodeWorkingOutline/> : <IoCodeSlashOutline/>}
          </span>
          <span>Query</span>
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu variant="dark" align="end" className="text-small shadow">
        <Form className="my-n2" style={{width: 320}} onSubmit={onSubmit}>
          <Accordion activeKey={activeKey} flush>
            <Accordion.Item eventKey="fieldId">
              <Accordion.Header onClick={() => handleAccordion('fieldId')}>
                <span className="w-100 d-flex justify-content-between align-items-center">
                  <span>Order by</span>
                  {fieldId && <small style={{ maxWidth: '140px'}} className="font-monospace text-muted mx-3 text-truncate">{fieldId}</small>}
                </span>
              </Accordion.Header>
              <Accordion.Body style={{backgroundColor: 'var(--bs-gray-100)'}} className="text-black">
                <Controller
                  render={({ field: { value, onChange, ...field }}) => (
                    <Creatable<OptionType, false>
                      styles={selectStyles}
                      components={selectComponents}
                      value={fieldOptions.find(o => o.value === value) ?? (value ? { value, label: value! } : null)}
                      onChange={o => onChange(o?.value ?? undefined)}
                      onCreateOption={val => onChange(val ?? undefined)}
                      isClearable
                      placeholder="Select or type field"
                      options={fieldOptions}
                      formatCreateLabel={() => 'Use custom key'}
                      {...field}
                    />
                  )}
                  control={control}
                  name="fieldId"
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="condition">
              <Accordion.Header onClick={() => handleAccordion('condition')}>
                <span className="w-100 d-flex justify-content-between align-items-center">
                  <span>Condition</span>
                  {condition && <small style={{ maxWidth: '140px'}} className="font-monospace text-muted mx-3 text-truncate">{condition}{fieldValue !== undefined ? ` ${fieldValue}` : ''}</small>}
                </span>
              </Accordion.Header>
              <Accordion.Body style={{backgroundColor: 'var(--bs-gray-100)'}} className="text-black">
                <Controller
                  render={({ field: { value, onChange, ...field }}) => (
                    <Select<OptionType>
                      styles={selectStyles}
                      components={selectComponents}
                      value={conditions.find((c) => c.value === value) ?? null}
                      onChange={(option) => onChange(option?.value ?? undefined)}
                      isClearable
                      placeholder="Select condition"
                      options={conditions}
                      {...field}
                    />
                  )}
                  control={control}
                  name="condition"
                />

                {condition && fieldId && (
                  <Controller
                    render={({ field }) => renderValueControl(field)}
                    control={control}
                    name="fieldValue"
                    shouldUnregister
                  />
                )}
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="order">
              <Accordion.Header onClick={() => handleAccordion('order')}>
                <span className="w-100 d-flex justify-content-between align-items-center">
                  <span>Sort results</span>
                  {Boolean(order) && <small style={{ maxWidth: '140px'}} className="font-monospace text-muted mx-3 text-truncate">{order === 'asc' ? 'ASC' : 'DESC'}</small>}
                </span>
              </Accordion.Header>
              <Accordion.Body style={{backgroundColor: 'var(--bs-gray-100)'}}>
                <Controller
                  render={({field}) => (
                    <ButtonGroup className="w-100">
                      {['asc', 'desc'].map((option, index) => (
                        <Button key={index} variant="outline-secondary" active={field.value === option} onClick={() => field.onChange(option)}>{option === 'asc' ? 'Ascending' : 'Descending'}</Button>
                      ))}
                    </ButtonGroup>
                  )}
                  control={control}
                  name="order"
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="limit">
              <Accordion.Header onClick={() => handleAccordion('limit')}>
                <span className="w-100 d-flex justify-content-between align-items-center">
                  <span>Limit results</span>
                  {Boolean(limit) && <small style={{ maxWidth: '140px'}} className="font-monospace text-muted mx-3 text-truncate">{limit}</small>}
                </span>
              </Accordion.Header>
              <Accordion.Body style={{backgroundColor: 'var(--bs-gray-100)'}}>
                <Controller
                  render={({field}) => (
                    <ButtonGroup className="w-100">
                      {[25,50,100].map((option, index) => (
                        <Button key={index} variant="outline-secondary" active={field.value === option} onClick={() => field.onChange(option)}>{option ?? 'All'}</Button>
                      ))}
                    </ButtonGroup>
                  )}
                  control={control}
                  name="limit"
                />

              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <hr className="my-0" style={{ color: 'var(--bs-gray-300)', opacity: 1}}/>
          <ButtonToolbar className="p-3 d-flex justify-content-between" style={{ backgroundColor: 'var(--bs-gray-100)' }}>
            <Button variant="outline-secondary" onClick={onReset}>Reset</Button>
            <Button type="submit" disabled={!isDirty}>Apply</Button>
          </ButtonToolbar>
        </Form>
      </Dropdown.Menu>
    </Dropdown>
  )
}
