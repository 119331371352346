import React, {RefCallback} from 'react'
import {FaSort, FaSortDown, FaSortUp} from "react-icons/all"
import {DataSortOrder} from "types"
import cn from 'classnames'

type Props = React.ThHTMLAttributes<HTMLTableHeaderCellElement> & {
  id: string;
  order?: DataSortOrder;
  onSort?: (id: string, order: DataSortOrder) => void;
  thRef?: RefCallback<HTMLTableHeaderCellElement | null>
};

export const Th: React.FC<Props> = ({id, children, order, onSort, thRef, ...rest}) => {
  return (
    <th
      {...rest}
      style={{...rest.style, ...order && { backgroundColor: 'var(--v-light-blue)' }}}
      ref={thRef}
      className={cn(rest.className, 'cursor-pointer user-select-none')}
      {...onSort && { onClick: () => onSort(id, order === 'asc' ? 'desc' : 'asc')}}
    >
      {onSort ? (
        <span className="d-flex align-items-center justify-content-between">
          <span className="text-truncate">{children}</span>
          <span style={{color: order ? 'var(--bs-blue-500)' : 'var(--bs-gray-400)'}}>
            {order ? (order === 'asc' ? <FaSortUp/> : <FaSortDown/>) : <FaSort/>}
          </span>
        </span>
      ) : (
        children
      )}
    </th>
  )
}
