import {OptionType} from "types"
import {GroupBase, SelectComponentsConfig, StylesConfig, ThemeConfig} from "react-select"
import SelectOption from './SelectOption'

export const selectStyles: StylesConfig<OptionType, false> = {
  menu: (provided) => ({
    ...provided,
    zIndex: 3,
  }),
  option: (provided, state) => ({
    ...provided,
    ...state.isSelected && {
      backgroundColor: state.theme.colors.primary25,
      color: state.theme.colors.neutral90
    },
    display: 'flex',
    padding: '.5rem 1rem .5rem .75rem',
    alignItems: 'center'
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '.125rem .5rem'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '.125rem .5rem'
  }),
  control: (provided) => ({
    ...provided,
    minHeight: '2rem'
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0
  }),
}

export const selectTheme:ThemeConfig = (theme) => { console.log(theme); return {
  ...theme,
  // colors: {
  //   ...theme.colors,
  //   primary25: 'hotpink',
  // },
}}

export const selectComponents:SelectComponentsConfig<OptionType, false, GroupBase<OptionType>> = {
  Option: SelectOption
}
