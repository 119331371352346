import React, {useContext} from 'react'

import {Alert, Button, FloatingLabel, Form} from "react-bootstrap"
import {Controller, useForm} from "react-hook-form"
import cn from 'classnames'
import logo from "images/app-logo-colored.svg"
import {useSignin} from "hooks"

import {UserContext} from "services/context"
import {ROUTES} from "config"
import { useLocation, Redirect } from 'react-router-dom'

import styles from './Signin.module.scss'
import {Loading} from "components/Loading"

type Props = {

};

export const Signin: React.FC<Props> = () => {

  const { user, isReady } = useContext(UserContext)
  const { isLoading, error, signin } = useSignin()
  const { control, handleSubmit, formState: { errors } } = useForm()
  const { search } = useLocation()
  const params = new URLSearchParams(search)

  const onSubmit = handleSubmit(({ email, password }) => {
    signin(email, password)
  })

  if (!isReady) return (
    <Loading/>
  )

  if (user) return (
    <Redirect to={params.get('back') ?? ROUTES.HOME} />
  )

  return (
    <div className={cn(styles.Signin, 'text-center')}>
      <main className={styles.form}>
        <Form onSubmit={onSubmit}>
          <img className="mb-4" src={logo} alt="" width="72" height="57"/>

          <Controller
            render={({field}) => (
              <FloatingLabel label="Email">
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  isInvalid={errors?.email}
                  {...field}
                />
              </FloatingLabel>
            )}
            rules={{
              required: true
            }}
            control={control}
            name="email"
          />
          <Controller
            render={({field}) => (
              <FloatingLabel label="Password">
                <Form.Control
                  type="password"
                  placeholder="Password"
                  isInvalid={errors?.password}
                  {...field}/>
              </FloatingLabel>
            )}
            rules={{
              required: true
            }}
            control={control}
            name="password"
          />

          <Button disabled={isLoading} variant="primary" size="lg" className="w-100" type="submit">
            {isLoading ? "Loading..." : "Sign in"}
          </Button>
          {error && (
            <Alert variant="danger" className="mt-3">
              {error.message}
            </Alert>
          )}
          <p className="mt-5 mb-3 text-muted">&copy; {new Date().getFullYear()} Tech-5</p>
        </Form>
      </main>
    </div>
  )
}
