import firebase from "firebase/app"
import {useCallback, useState} from "react"

interface UseSignin {
  isLoading: boolean;
  error: Error | null;
  signin: (email: string, password: string) => void;
}

export function useSignin(): UseSignin {
  const [ isLoading, setLoading ] = useState(false)
  const [ error, setError ] = useState<Error | null>(null)

  const signin = useCallback(async (email: string, password: string) => {
    setError(null)
    setLoading(true)
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password)
    } catch (e) {
      setError(e as Error)
    } finally {
      setLoading(false)
    }

  }, [])

  return {
    isLoading,
    error,
    signin
  }
}
