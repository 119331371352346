import React from 'react'
import {DataPage} from "containers/DataPage"
import {DataDetails} from "containers/DataDetails"
import {DataField} from "types"
import {renderReportLink} from "components/helpers"
import {COLLECTIONS, ROUTES} from "config"

const fields: DataField[] = [
  {
    id: 'id',
    name: 'ID',
    type: 'string' as const,
    isCustom: true,
    renderList: renderReportLink,
    renderDetails: true,
    listStyle: {width: '10%'}
  },
  {
    id: 'install',
    name: 'Installs',
    type: 'array' as const,
    renderList: true,
    renderDetails: true,
    listStyle: {width: '5%'}
  },
  {
    id: 'uninstall',
    name: 'Uninstalls',
    type: 'array' as const,
    renderList: true,
    renderDetails: true,
    listStyle: {width: '5%'}
  },
  {
    id: 'session',
    name: 'Sessions',
    type: 'map' as const,
    renderList: true,
    renderDetails: true,
    listStyle: {width: '5%'}
  },
  {
    id: 'clear',
    name: 'Clear',
    type: 'map' as const,
    renderList: true,
    renderDetails: true,
    listStyle: {width: '5%'}
  },
  {
    id: 'join',
    name: 'Joins',
    type: 'map' as const,
    renderList: true,
    renderDetails: true,
    listStyle: {width: '5%'}
  },
  {
    id: 'link',
    name: 'By link',
    type: 'map' as const,
    renderList: true,
    renderDetails: true,
    listStyle: {width: '5%'}
  },
  {
    id: 'start',
    name: 'Start',
    type: 'map' as const,
    renderList: true,
    renderDetails: true,
    listStyle: {width: '5%'}
  },
  {
    id: 'pause',
    name: 'Pause',
    type: 'map' as const,
    renderList: true,
    renderDetails: true,
    listStyle: {width: '5%'}
  },
  {
    id: 'proceed',
    name: 'Proceed',
    type: 'map' as const,
    renderList: true,
    renderDetails: true,
    listStyle: {width: '5%'}
  },
]

export const Reports: React.FC = () => {

  return (
    <>
      <DataPage title="Reports" collection={COLLECTIONS.REPORTS} fields={fields}/>
      <DataDetails
        rootPath={ROUTES.REPORTS}
        collection={COLLECTIONS.REPORTS}
        fields={fields}
      />
    </>
  )
}
