import React, {Dispatch, SetStateAction} from 'react'

import styles from './DataTable.module.scss'

import {WithTooltip} from "components/WithTooltip"
import cn from 'classnames'

type Props = {
  length?: number;
  onShow: Dispatch<SetStateAction<boolean>>
  start?: boolean;
  end?: boolean;
};

export const FilteredRows: React.FC<Props> = ({ length, onShow, start, end }) => {
  if (length) {
    return (
      <div className={styles.row}>
        <WithTooltip tooltip={`Filtered ${length} rows`}>
          <div className={styles.badge} onClick={() => onShow(val => !val)}>
            {length}
          </div>
        </WithTooltip>
      </div>
    )
  } else return (
    <WithTooltip tooltip="This row is filtered out">
      <div className={cn(styles.flag, start && styles.start, end && styles.end)} onClick={() => onShow(val => !val)}/>
    </WithTooltip>
  )

}
