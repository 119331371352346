import firebase from "firebase/app"
import {createContext, Dispatch, SetStateAction} from "react"

export type UseUserContext = {
  user: firebase.User | null;
  isReady: boolean;
  setUser: Dispatch<SetStateAction<firebase.User | null>>;
};
export const UserContext = createContext<UseUserContext>({
  user: null,
  isReady: false,
  setUser: () => {}
})
