import React, {ReactElement} from 'react'

import {OverlayTriggerProps, OverlayTrigger, Tooltip, TooltipProps} from "react-bootstrap"

type Props = TooltipProps & {
  children: ReactElement<any, string>
  tooltip: string;
  overlayProps?: Partial<OverlayTriggerProps>;
};

export const WithTooltip: React.FC<Props> = ({ children, tooltip, overlayProps, ...rest}) => {
  return (
    <OverlayTrigger
      {...overlayProps}
      placement="bottom"
      overlay={
        <Tooltip {...rest}>
          {tooltip}
        </Tooltip>
      }
    >
      {children}
    </OverlayTrigger>
  )
}
