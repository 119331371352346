import * as React from 'react'
import {Spinner, SpinnerProps} from 'react-bootstrap'

import styles from './Loading.module.scss'
import classNames from "classnames"

type Props = Partial<SpinnerProps> & {
  inline?: boolean;
};

export const Loading: React.FC<Props> = ({ inline, ...rest }) => {

  return (
    <div className={classNames(styles.Loading, inline && styles.inline)}>
      <Spinner animation="border" variant="primary" {...rest} />
    </div>
  )
}
