import React from 'react'

import {useFetchDocument} from "hooks"
import {DataDoc} from "./DataDoc"

type Props = {
  id: string
  collection: string;
};

export const DataDocTab: React.FC<Props> = ({ collection, id }) => {
  const { doc, isLoading } = useFetchDocument(collection, id)
  return (
    <DataDoc doc={doc} isLoading={isLoading}/>
  )
}
