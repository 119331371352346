import React, {ReactNode, useEffect, useState} from 'react'
import {Modal, Nav, NavLink} from "react-bootstrap"
import {Route, useHistory, useParams, useRouteMatch} from "react-router"
import firebase from "firebase/app"
import {DataField} from "types"
import {Loading} from "components/Loading"
import {LinkContainer} from "react-router-bootstrap"
import {ROUTES} from "config"
import {useFetchDocument} from "hooks"
import {DataDoc} from "components/DataDoc"
import {Empty} from "components"

type DataDetailsTab = {
  name: string;
  render: (doc: firebase.firestore.DocumentSnapshot) => ReactNode;
  path: string;
}

type Props = {
  rootPath?: string;
  collection: string;
  fields: DataField[];
  renderTitle?: (doc: firebase.firestore.DocumentSnapshot) => ReactNode;
  tabs?: DataDetailsTab[]
}

export const DataDetails: React.FC<Props> = ({rootPath = ROUTES.HOME, collection, renderTitle, tabs = []}) => {

  const {docId} = useParams<{ docId: string }>()
  const {path, url} = useRouteMatch()
  const history = useHistory()
  const {doc, isLoading} = useFetchDocument(collection, docId)
  const [showDetails, setShowDetails] = useState(Boolean(docId))

  useEffect(() => {
    if (docId) {
      setShowDetails(true)
    } else {
      setShowDetails(false)
    }
  }, [docId])

  return (
    <Modal show={showDetails} onHide={() => history.push(rootPath)} size="lg" height={600} scrollable>
      {doc?.exists && (
        <Modal.Header closeButton className="px-4 flex-row justify-content-between"
                    style={{backgroundColor: 'var(--bs-gray-100)'}}>
        {/*<Modal.Title className="mb-0 h5 fw-bold" style={{ minWidth: 80 }}>Instance</Modal.Title>*/}
        <div className="d-flex flex-row align-items-center">
          <Modal.Title
            className="h5 fw-bold me-3">{renderTitle ? renderTitle(doc!) : docId}</Modal.Title>
          {doc!.exists && (
            <Nav variant="pills">
              <Nav.Item>
                <LinkContainer to={url} exact>
                  <NavLink active={false} className="cursor-pointer">Details</NavLink>
                </LinkContainer>
              </Nav.Item>
              {tabs.map(tab => (
                <Nav.Item key={tab.name}>
                  <LinkContainer to={`${url}${tab.path}`}>
                    <NavLink eventKey={tab.name} className="cursor-pointer">{tab.name}</NavLink>
                  </LinkContainer>
                </Nav.Item>
              ))}
            </Nav>
          )}
        </div>
      </Modal.Header>
      )}
      <Modal.Body style={{minHeight: 600}} className="h-100 d-flex flex-column flex-fill">
        {isLoading ? <Loading/> : doc?.exists ? (
          <>
            <Route path={path} exact>
              <DataDoc doc={doc} isLoading={isLoading}/>
            </Route>
            {tabs.map(tab => (
              <Route key={tab.name} path={`${path}${tab.path}`}>
                {tab.render(doc!)}
              </Route>
            ))}
          </>
        ) : <Empty/>}
      </Modal.Body>
    </Modal>
  )
}
