import React, {ReactNode} from 'react'
import {IoAlertCircleOutline, IoWarningOutline} from "react-icons/all"
import {IconType} from "react-icons"
import {ROUTES} from "config"
import {Button} from "react-bootstrap"

type Props = {
  icon?: IconType,
  title?: ReactNode;
  text?: ReactNode;
}

export const Empty: React.FC<Props> = ({
  icon : Icon= IoAlertCircleOutline,
  title = 'Nothing to show',
  text = 'Current query has returned no documents. Please try another field or condition.'
}) => {
  return (
    <div className="flex-fill d-flex flex-column h-100 justify-content-center align-items-center text-center">
        <div className="d-flex flex-column align-items-center user-select-none" style={{ color: 'var(--bs-gray-500)', maxWidth: 300}}>
          <Icon className="fs-1 mb-3"/>
          <h5 className="fw-bold">{title}</h5>
          <p>{text}</p>
        </div>
    </div>
  )
}

export const QueryError: React.FC<{ error: string }> = ({ error }) => (
  <Empty icon={IoWarningOutline} title="Query failed:" text={error}/>
)

export const NotFound:React.FC = () => (
  <Empty icon={IoWarningOutline} title="Not found" text={<>Nothing found here. Try another page. <br/><br/> <Button href={ROUTES.HOME}>Go home</Button></>}/>
)
