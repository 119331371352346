import React, {RefObject, useMemo, useState} from 'react'

import styles from './DataTable.module.scss'

import {Table} from "react-bootstrap"
import {DataField, DataQueryValue, DataSortOrder} from "types"

import {Loading, Empty, Td, THead, QueryError} from "components"
import firebase from "firebase/app"
import cn from 'classnames'
import { FilteredRows } from './FilteredRows'

type Props = {
  fields: DataField[],
  onSort?: (name: string, order: DataSortOrder) => void;
  query: DataQueryValue;
  docs: firebase.firestore.DocumentSnapshot[];
  isLoading: boolean;
  rowRefs?: RefObject<Array<HTMLTableHeaderCellElement | null>>
  isScrolled?: boolean | 'end';
  error: string | null;
};

export const DataTable: React.FC<Props> = ({
  fields,
  onSort,
  query,
  docs= [],
  isLoading,
  rowRefs,
  isScrolled = false,
  error
}) => {

  const [ showFiltered, setShowFiltered ] = useState(false)

  // noinspection JSUnusedLocalSymbols
  const testFilter = (doc: firebase.firestore.DocumentSnapshot) => {
    return true//doc && doc.get('isInstalled') === false
  }

  const filterResult:number[] = useMemo(() => {
    let length = 1
    return docs.map((doc, index) => {
      if (testFilter(doc)) {
        length = 1
        return 0
      } else if (!docs[index+1] || testFilter(docs[index+1])) {
        const result = length
        length = 0
        return result
      } else {
        length += 1
        return -1
      }
    })
  }, [docs])

  if (!docs.length || error) {
    return isLoading ? <Loading inline/> : error ? <QueryError error={error}/> : <Empty/>
  }

  return (
    <Table bordered striped hover className={cn('mb-0', isScrolled && 'mt-n5', styles.DataTable)}>
      <THead
        sortId={query.fieldId}
        sortOrder={query.order}
        {...{fields, onSort, rowRefs}}
      />
      <tbody>
      {docs.map((doc, index) => (
        (!showFiltered && filterResult[index] ? (
          filterResult[index] > 0 ? (
            <tr key={doc.id}>
              <td colSpan={fields.length+1} className="p-0">
                <FilteredRows length={filterResult[index]} onShow={setShowFiltered}/>
              </td>
            </tr>
          ) : null
        ) : (
          <tr key={doc.id} {...filterResult[index] && {className: styles.filtered}}>
            <td style={{ width: '0.1%'}}>
              {Boolean(filterResult[index]) && (
                <FilteredRows
                  onShow={setShowFiltered}
                  start={!filterResult[index-1]}
                  end={filterResult[index] > 0}
                />
              )}
              <small className="d-block text-muted w-100 text-center">{query.page * (query.limit ?? 0) + index + 1}</small>
            </td>
            {fields.filter(f => Boolean(f.renderList)).map(f => (
              <Td key={f.id} field={f} doc={doc}/>
            ))}
          </tr>)
        )
      ))}
      </tbody>
    </Table>
  )
}
