import React from 'react';
import ReactDOM from 'react-dom';
import 'index.scss';
import App from 'App';
import reportWebVitals from 'reportWebVitals';

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import {DEV_MODE, FIREBASE_CONFIG, FIREBASE_CONFIG_DEV} from "config"

firebase.initializeApp(DEV_MODE ? FIREBASE_CONFIG_DEV : FIREBASE_CONFIG)

// In dev instances we should use emulators for all backend services
if (DEV_MODE) {
  firebase.firestore().useEmulator("localhost", 8084)
  firebase.auth().useEmulator('http://localhost:9099/')
} else {
  firebase.auth()
  firebase.firestore()
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
