import React from 'react'

import {Button, Form, FormControl, InputGroup} from "react-bootstrap"
import {IoSearchOutline} from "react-icons/all"
import {Controller, useForm} from "react-hook-form"
import {useHistory, useLocation} from "react-router"

export const JumpTo: React.FC = () => {

  const { pathname } = useLocation()
  const history = useHistory()
  const { control, handleSubmit, formState: { errors } } = useForm({ defaultValues: { id: '' }})
  const onSubmit = handleSubmit(({ id }) => {
    if (id) history.push(`${pathname}/${id}`)
  })

  return (
    <Form onSubmit={onSubmit}>
      <InputGroup>
        <Controller
          control={control}
          render={({ field }) => (
            <FormControl
              type="text"
              placeholder="ID"
              {...field}
              isInvalid={!!errors.id}
              className="font-monospace"
              style={{ width: 190 }}
            />
          )}
          rules={{ required: true }}
          name="id"
        />

        <Button type="submit" className="d-flex align-items-center"><IoSearchOutline className="fs-5"/></Button>
      </InputGroup>
    </Form>
  )
}
