import {TFooter} from "components/DataTable"
import {useCallback, useRef} from "react"

export const useFooterKeyDownHandle = () => {
  const footerRef = useRef<React.ElementRef<typeof TFooter>>(null)

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'ArrowLeft') {
      footerRef.current?.prevPage()
    } else if (e.key === 'ArrowRight') {
      footerRef.current?.nextPage()
    }
  }, [])

  return {
    footerRef,
    handleKeyDown
  }
}
