/**
 * Generate 6-digits color based on string (id)
 * @param str{string}
 */
import {useEffect, useRef} from "react"

export const stringToColor = (str: string) => {
  if (!str) return '#000000'
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  let colour = '#'
  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2)
  }
  return colour
}

/**
 * React hook to compare var with its previous value
 * @param value
 */
export function usePrevious<T>(value: T) {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

/**
 * Pick Jira ID (subdomain) from Atlassian URL
 * @param value
 */
export function getJiraIdFromUrl(value: string) {
  if (!value) return value

  const matches = value.match(/https:\/\/(.+).atlassian\.net/)

  return matches ? matches[1] : value
}

/**
 * Invert color
 * @param hex
 */
export function invertHex(hex: string): string {
  if (hex.substr(0, 1) === '#') hex = hex.replace('#', '')
  return (Number(`0x1${hex}`) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase()
}
